import { react } from "react";
import VisitHeader from "../visit-home/header";
import VisitFooter from "../visit-home/footer";
import * as css from './style'
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import mainAxios from "../axios";
import { Snackbar } from '@mui/material';
import Snackbarcomponent from "../user-panel/components/Snackbar";
import { useDispatch } from "react-redux";
import { openNotification, closeNotification } from "../redux/Actions/index";

const VisitLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [login, setLogin] = useState({ email: localStorage.getItem('email') || '', password: localStorage.getItem('password') || '' })
    const [rememberMe, setRemeberMe] = useState(false)
    useEffect(() => { localStorage.getItem('email') ? setRemeberMe(true) : setRemeberMe(false) }, [])
    const onChangeLogin = (e) => {
        setLogin({ ...login, [e.target.name]: e.target.value })
    }
    const checkUserAccess = () => {
        let formData = new FormData()
        formData.append('email', login.email)
        formData.append('password', login.password)

        mainAxios.post('/login', formData).then((res) => {
            if (res.data.status) {
                localStorage.setItem('authToken', res.data.data.access_token)
                dispatch(openNotification({ open: true, message: 'Successfully Login', type: 'success' }))
                navigate('/dashboards')
            }
            else {
                dispatch(openNotification({ open: true, message: 'Entered Wrong Username or Password', type: 'error', onClose: handleClose }))
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const handleClose = () => {
        dispatch(closeNotification({ open: false, message: '', type: '' }))
    }
    const checkUserAccessonEnter = (e) => {

        let a = e.which
        if (a == 13) {
            checkUserAccess()
        }
    }
    const remember = (e) => {
        if (e.target.checked) {
            setRemeberMe(true)
            localStorage.setItem("email", login.email);
            localStorage.setItem("password", login.password)
        }
        else {
            setRemeberMe(false)
            localStorage.removeItem("email"); localStorage.removeItem("password")
        }
    }
    return (
        <css.LoginStyle>
            <VisitHeader />
            <div class="page-section" id="contact-us">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 py-3 wow fadeInUp">
                            <img src="../assets/img/icon/login.jpg" class="img-fluid" alt="Responsive image" />
                        </div>
                        <div class="col-lg-6 py-3 wow fadeInUp" style={{ textAlign: 'center' }}>

                            <h2 class="title-section"><span class="marked">Log</span> In</h2>
                            <div class="divider  mx-auto"></div>


                            <div class="py-2">
                                <input type="text" name="email" value={login.email} class="form-control" placeholder="Email" onChange={(e) => { onChangeLogin(e) }} required />

                            </div>

                            <div class="py-2">
                                <input type="password" name="password" value={login.password} class="form-control" onKeyDown={(e) => { checkUserAccessonEnter(e) }} placeholder="Password" onChange={(e) => { onChangeLogin(e) }} required />
                            </div>
                            <div class="d-flex justify-content-between align-items-center">

                                <div class="form-check mb-0">
                                    <input class="form-check-input me-2" checked={rememberMe} type="checkbox" onChange={(e) => { remember(e) }} value="" id="form2Example3" />
                                    <label class="form-check-label" for="form2Example3">
                                        Remember me
                                    </label>
                                </div>
                                <a href="#!" class="text-body">Forgot password?</a>
                            </div>
                            <button disabled={login.email == '' || login.password == '' ? true : false} type="submit" onClick={() => checkUserAccess()} class="btn btn-primary rounded-pill mt-4">Login</button>
                            <div class="text-center text-lg-start mt-4 pt-2">

                                <p class="small fw-bold mt-2 pt-1 mb-0">Don't have an account?<NavLink to={'/register'}> <a href=""
                                    class="link-danger">Sign Up</a></NavLink></p>
                            </div>



                        </div>


                    </div>
                </div>
            </div>

            <VisitFooter />
        </css.LoginStyle>
    )
}
export default VisitLogin