import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const LogoWrapper = styled(Link)(
    ({ theme }) => `
          color: ${theme.palette.text.primary};
          display: flex;
          text-decoration: none;
          width: 53px;
          margin: 0 auto;
          font-weight: ${theme.typography.fontWeightBold};
          font-size: x-large;

          .h6{
            margin-top: 12px;
            color: white;
          }
  `
);

export const LogoSignWrapper = styled(Box)(
    () => `
          width: 52px;
          height: 38px;
  `
);

export const LogoSign = styled(Box)(
    ({ theme }) => `
          background: ${theme.general.reactFrameworkColor};
          width: 18px;
          height: 18px;
          border-radius: ${theme.general.borderRadiusSm};
          position: relative;
          transform: rotate(45deg);
          top: 3px;
          left: 17px;
  
          &:after, 
          &:before {
              content: "";
              display: block;
              width: 18px;
              height: 18px;
              position: absolute;
              top: -1px;
              right: -20px;
              transform: rotate(0deg);
              border-radius: ${theme.general.borderRadiusSm};
          }
  
          &:before {
              background: ${theme.palette.primary.main};
              right: auto;
              left: 0;
              top: 20px;
          }
  
          &:after {
              background: ${theme.palette.secondary.main};
          }
  `
);

export const LogoSignInner = styled(Box)(
    ({ theme }) => `
          width: 16px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: 12px;
          z-index: 5;
          border-radius: ${theme.general.borderRadiusSm};
          background: ${theme.header.background};
  `
);