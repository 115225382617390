import React from 'react'
import axios, { AxiosResponse } from 'axios'

/**
 * api Request base url
 */
const mainAxios = axios.create({
    baseURL: "https://ivisiting-api.kpswebsolution.com/api/api"
})
/**
 * Bind request inrterceptore
 */
mainAxios.interceptors.request.use(function (config) {

    let headers
    headers = {
        'Content-Type': 'application/json ',
        'authToken': sessionStorage.getItem('authToken'),
        // 'store_id': sessionStorage.getItem('store_id'),
        //'Api_token': 'eyJpdiI6IjgzclIvTCttU0pMdEJzODhkSE0zRkE9PSIsInZhbHVlIjoiOEdOTTZPUmtGMG5acVdiUWI5RlBNdz09IiwibWFjIjoiZmZiMWYwN2Y2MDk2Y2MzYWI5YTI0MzhhOTVmMTk4Nzc3YzhjMzQ1MjFkMjFkMGE3ZjkzNTAwYTk4ZTM5MDAwNiJ9  ',
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
    config.headers = headers;
    return config;
});
/**
 * Request response interceptor
 */
mainAxios.interceptors.response.use(function (res) {

    // if (res.data.status == false && res.data.message != "Data Not Find") {
    // }
    // else {
    //     if (res.data.message != "Data Find Successfully" && res.data.message != "Data Not Find") {
    //     }
    // }
    return res
})
export default mainAxios