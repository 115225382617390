import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { SidebarProvider } from './user-panel/contexts/SidebarContext';

import { Provider } from 'react-redux'
import  Store  from "./redux/Store/index";

ReactDOM.render(

  <Provider store={Store}>
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter history={BrowserRouter}>

          <App />
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();  