import { react } from "react";
import VisitHeader from "./header";
import VisitBody from "./body";
import VisitFooter from "./footer";

const VisitHome = () => {
    
    return (
        <>
            <VisitHeader />
            <VisitBody />
            <VisitFooter />
        </>
    )
}
export default VisitHome