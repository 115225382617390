import { react } from "react";
import { NavLink, useHistory } from "react-router-dom";
import * as css from './style'
const VisitHeader = () => {


    return (
        <css.HeaderStyle>
            <header>
                <nav class="navbar navbar-expand-lg navbar-light shadow-sm bg-white">
                    <div class="container">
                        <a href="/home" class="navbar-brand"><h6 className="h6">i</h6><span class="text-primary">Visiting</span></a>

                        <button class="navbar-toggler" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="navbar-collapse collapse" id="navbarContent">
                            <ul class="navbar-nav ml-lg-4 pt-3 pt-lg-0">
                                <NavLink class="nav-item " to={'/home'}>
                                    <a href="/home" class="nav-link">Home</a>
                                </NavLink>
                                <NavLink class="nav-item" to={'/about-us'}>
                                    <a href="/about-us" class="nav-link">About Us</a>
                                </NavLink>
                                <NavLink class="nav-item" to={'/pricing'}>
                                    <a href="/pricing" class="nav-link">Pricing</a>
                                </NavLink>
                                <NavLink class="nav-item" to={'/contact-us'}>
                                    <a href="/contact-us" class="nav-link">Contact</a>
                                </NavLink>
                            </ul>

                            <div class="ml-auto">
                                <NavLink to={'/login'}>  <a href="/login" class="btn btn-outline rounded-pill">My Account</a></NavLink>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </css.HeaderStyle>
    )
}
export default VisitHeader