import VisitHome from '../visit-home/index';
import {
    Routes,
    Route,
} from "react-router-dom";
import * as constant from "../constant.js";

export const VisitRoute = () => {
    return (
      
            <Routes>
                <Route path={constant.BASE_URL + "/"} element={<VisitHome />} />
                <Route path={constant.BASE_URL + "/home"} element={<VisitHome />} />
                <Route path={constant.BASE_URL + "/about-us"} element={<VisitHome />} />
                <Route path={constant.BASE_URL + "/pricing"} element={<VisitHome />} />
                <Route path={constant.BASE_URL + "/contact-us"} element={<VisitHome />} />
          
                

            </Routes>
       
    )
}