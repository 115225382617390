let SITE_ENVIRONMENT = "PRODUCTION";

// FOR LOCAL
let LOCAL_URL = "https://a3e26094e860.ngrok.io/api";
let LOCAL_BASE_URL = "";


// FOR LIVE 
let LIVE_URL = "";
let LIVE_BASE_URL = "";


export const URL = SITE_ENVIRONMENT === "DEVELOPMENT" ? LOCAL_URL : LIVE_URL;
export const BASE_URL = SITE_ENVIRONMENT === "DEVELOPMENT" ? LOCAL_BASE_URL : LIVE_BASE_URL;