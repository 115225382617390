import { Box, styled, } from '@mui/material'
// import * as constant from "../../../src/constant";

export const SidebarWrapper = styled(Box)(
    ({ theme }) => `
        width: 235px;
        min-width: 235px;
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
       
  `
);