import styled from 'styled-components';
// import * as constant from "../../../src/constant";

export const HeaderStyle = styled.div`
   
    .navbar{
         position: fixed;
         width: 100%;
    }

    @media (min-width: 200px) {
        .navbar {
            top:0;
        }
      }
    .h6{
        display: contents;
    }
`;
