import { NOTIFICATOIN_OPEN, NOTIFICATOIN_CLOSE } from "../Actionstypes/index";

const initialState = {
    open: false,
    message: '',
    type: '',
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATOIN_OPEN:
            return {
                ...state,
                open: true,
                message: action.payload.message,
                type: action.payload.type
            };

        case NOTIFICATOIN_CLOSE:
            return {
                ...state,
                open: false,
                message: '',
                type: '',
                onClose:action.payload.onClose
            };
        default:
            return state;
    }
};
export default cartReducer