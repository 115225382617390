import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { useDispatch } from "react-redux";
import { openNotification, closeNotification } from "../../../redux/Actions/index";
export default function Snackbarcomponent(props) {
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal } = state;
    const dispatch = useDispatch();

    const handleClose = (e) => {
        dispatch(closeNotification({ open: false, message: '', type: '' }))
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={props.open}
                autoHideDuration={3000}

            >
                <Alert
                    onClose={() => handleClose()}
                    severity={props.type}
                    variant="filled"
                    sx={{ width: '400%' }}
                >
                    {props.message}
                </Alert>
            </Snackbar>



        </div>
    );
}
