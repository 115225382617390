import './App.css';
import { useRoutes } from 'react-router-dom';
import { VisitRoute } from './routes';
import ThemeProvider from './user-panel/theme/ThemeProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import router from './user-panel/router';
import { Provider, useSelector } from 'react-redux'
import store from './redux/Store/index'
import Snackbarcomponent from './user-panel/components/Snackbar';
function App() {
  const state = useSelector((state) => state);
  const content = useRoutes(router);
  return (

    <ThemeProvider>
      <Snackbarcomponent type={state.type} message={state.message} open={state.open} />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <VisitRoute />
        {content}

      </LocalizationProvider>
    </ThemeProvider>

  );
}

export default App;
