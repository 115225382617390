import { NOTIFICATOIN_CLOSE, NOTIFICATOIN_OPEN } from "../Actionstypes/index";

const openNotification = (props) => {
    return {
        type: NOTIFICATOIN_OPEN,
        payload: { ...props }
    };
};

const closeNotification = (props) => {
    return {
        type: NOTIFICATOIN_CLOSE,
        payload: { ...props }
    };
};

export { openNotification, closeNotification };