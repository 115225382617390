import { react } from "react";

const VisitFooter = () => {
    return (
        <footer class="page-footer">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 py-2 text-left">
                        <div class="d-inline-block px-3">
                            <a href="#">Privacy</a>
                        </div>
                        <div class="d-inline-block px-3">
                            <a href="#contact-us">Terms</a>
                        </div>
                    </div>
                    <div class="col-sm-6 py-2 text-right">
                        <p id="copyright">&copy; 2023 <a href="https://kpswebsolution.com/">kpswebsolution</a>. All rights reserved</p>
                    </div>

                </div>
            </div>
        </footer>
    )
}
export default VisitFooter