import { react, useState } from "react";
import VisitHeader from "../visit-home/header";
import VisitFooter from "../visit-home/footer";
import * as css from './style'
import mainAxios from "../axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openNotification, closeNotification } from "../redux/Actions/index";
const VisitRegister = () => {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({ email: '', password: '', c_password: '' });
    const navigate = useNavigate();
    const onChangeData = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const registerValidation = () => {

        if (!validateEmail(userData.email)) {
            alert("wrong email")
            return false
        }
        else if (userData.password == "" && userData.c_password == "") {
            alert("enter password and c password")
            return false
        }
        else if (userData.c_password != userData.password) {
            alert("enter same password and c password")
            return false
        }
        else {
            return true
        }
    }
    const register = () => {
        if (registerValidation()) {

            let formData = new FormData()
            formData.append('email', userData.email)
            formData.append('password', userData.password)
            formData.append('c_password', userData.c_password)
            mainAxios.post('/register', formData).then((res) => {
                navigate('/login');
                dispatch(openNotification({ open: true, message: 'Registration Successfull!', type: 'success' }))

            }).catch((err) => {
                console.log(err)
            })
        }
    }


    return (
        <css.RegisterStyle>
            <VisitHeader />
            <div class="page-section" id="contact-us">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 py-3 wow fadeInUp">
                            <img src="../assets/img/icon/signup.jpg" class="img-fluid" alt="Responsive image" />
                        </div>
                        <div class="col-lg-6 py-3 wow fadeInUp" style={{ textAlign: 'center' }}>

                            <h2 class="title-section"><span class="marked">Sign</span> Up</h2>
                            <div class="divider  mx-auto"></div>


                            <div class="py-2">
                                <input type="text" name="email" class="form-control" placeholder="Email" onChange={(e) => onChangeData(e)} />
                            </div>
                            <div class="py-2">
                                <input type="password" name="password" class="form-control" placeholder="Password" onChange={(e) => onChangeData(e)} />
                            </div>
                            <div class="py-2">
                                <input type="password" name="c_password" class="form-control" placeholder="Confirm Password" onChange={(e) => onChangeData(e)} />
                            </div>

                            <button class="btn btn-primary rounded-pill mt-4" onClick={() => { register() }}>Sign Up</button>




                        </div>


                    </div>
                </div>
            </div>
            <VisitFooter />
        </css.RegisterStyle>
    )
}
export default VisitRegister