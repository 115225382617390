import { react, useEffect } from "react";
import $ from 'jquery';
import { useLocation } from "react-router-dom";

const VisitBody = () => {
    const location = useLocation();
    useEffect(() => {
        var path = location.pathname.slice(1)
        path = path == "" ? "home" : path
        if (path == "home" || path == "contact-us" || path == "pricing" || path == "about-us") {
            setTimeout(() => {
                $('html, body').animate({
                    scrollTop: $("#" + path).offset().top
                }, 10);
            }, 10);
        }

    }, [location])
    return (

        <main id="home">
            <div class="page-banner home-banner">
                <div class="container h-100">
                    <div class="row align-items-center h-100">
                        <div class="col-lg-6 py-3 wow fadeInUp">
                            <h1 class="mb-8">We provide <span class="marked">Paperless</span>  solution for your business!</h1>
                            <p class="text-lg mb-5">Ignite the most powerfull growth engine you have ever built for your company</p>

                            <a href="#feature" class="btn btn-outline border text-secondary">More Info</a>
                            <a href="#" class="btn btn-primary btn-split ml-2">Watch Video <div class="fab"><span
                                class="mai-play"></span></div></a>
                        </div>
                        <div class="col-lg-6 py-3 wow zoomIn">
                            <div class="img-place">
                                <img src="../assets/img/bg_image_1.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-section features" id="feature" >
                <div class="text-center wow fadeInUp">
                    <h2 class="title-section">Features</h2>
                    <div class="divider mx-auto"></div>
                </div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
                            <div class="d-flex flex-row">
                                <div class="img-fluid mr-3">
                                    <img style={{ height: "60px", width: "60px" }} src="../assets/img/icon/call.svg" alt="" />
                                </div>
                                <div>
                                    <h5>Call</h5>
                                    <p>On just one click call will directly on that number from the business card.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
                            <div class="d-flex flex-row">
                                <div class="img-fluid mr-3">
                                    <img style={{ height: "60px", width: "60px" }} src="../assets/img/icon/whatsapp.svg" alt="" />
                                </div>
                                <div>
                                    <h5>WhatsApp</h5>
                                    <p>We providing facility to the client can directly message on whatsapp from the designed card.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
                            <div class="d-flex flex-row">
                                <div class="img-fluid mr-3">
                                    <img style={{ height: "60px", width: "60px" }} src="../assets/img/icon/envelope.svg" alt="" />
                                </div>
                                <div>
                                    <h5>Email</h5>
                                    <p>Your client can email from the business designed card.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
                            <div class="d-flex flex-row">
                                <div class="img-fluid mr-3">
                                    <img style={{ height: "60px", width: "60px" }} src="../assets/img/icon/geo-alt.svg" alt="" />
                                </div>
                                <div>
                                    <h5>Navigation</h5>
                                    <p>Your client can directly navigation on your added address.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
                            <div class="d-flex flex-row">
                                <div class="img-fluid mr-3">
                                    <img style={{ height: "60px", width: "60px" }} src="../assets/img/icon/share.svg" alt="" />
                                </div>
                                <div>
                                    <h5>Share Unlimited</h5>
                                    <p>Share with anyone, anywhere and anytime from the dashboard.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4 py-3 wow fadeInUp">
                            <div class="d-flex flex-row">
                                <div class="img-fluid mr-3">
                                    <img style={{ height: "60px", width: "60px" }} src="../assets/img/icon/pencil-square.svg" alt="" />
                                </div>
                                <div>
                                    <h5>Easy To Update</h5>
                                    <p>It's vary easy to update all the details on your designed card from the dashboard.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 py-3 wow zoomIn">
                            <div class="img-place text-center">
                                <img src="../assets/img/bg_image_2.png" alt="" />
                            </div>
                        </div>
                        <div class="col-lg-6 py-3 wow fadeInRight">
                            <h2 class="title-section">We're <span class="marked">Dynamic</span> Team of Creatives People</h2>
                            <div class="divider mx-auto"></div>
                            <p>We provide marketing services to startups & small business to looking for partner for their digital
                                media, design & dev lead generation & communication.</p>
                            <div class="img-place mb-3">
                                <img src="../assets/img/testi_image.png" alt="" />
                            </div>
                            <a href="#" class="btn btn-primary">More Details</a>
                            <a href="#" class="btn btn-outline border ml-2">Success Stories</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="page-section counter-section">
                <div class="container">
                    <div class="row align-items-center text-center">
                        <div class="col-lg-4">
                            <p>Total Invest</p>
                            <h2>$<span class="number" data-number="816278"></span></h2>
                        </div>
                        <div class="col-lg-4">
                            <p>Yearly Revenue</p>
                            <h2>$<span class="number" data-number="216422"></span></h2>
                        </div>
                        <div class="col-lg-4">
                            <p>Growth Ration</p>
                            <h2><span class="number" data-number="73"></span>%</h2>
                        </div>
                    </div>
                </div>
            </div> */}

            <div class="page-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 py-3 wow fadeInLeft">
                            <h2 class="title-section">We're <span class="marked">ready to</span> Serve you with best</h2>
                            <div class="divider mx-auto"></div>
                            <p class="mb-5">We provide marketing services to startups & small business to looking for partner for their
                                digital media, design & dev lead generation & communication.</p>
                            <a href="#" class="btn btn-primary">More Details</a>
                            <a href="#pricing" class="btn btn-outline ml-2">See pricing</a>
                        </div>
                        <div class="col-lg-6 py-3 wow zoomIn">
                            <div class="img-place text-center">
                                <img src="../assets/img/bg_image_3.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-section">
                <div class="container">
                    <div class="text-center wow fadeInUp">
                        <div class="subhead">Why Choose Us</div>
                        <h2 class="title-section">Your <span class="marked">Comfort</span> is Our Priority</h2>
                        <div class="divider mx-auto"></div>
                    </div>

                    <div class="row mt-5 text-center">
                        <div class="col-lg-4 py-3 wow fadeInUp">
                            <div class="display-3"><span class="mai-shapes"></span></div>
                            <h5>High Performance</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum, sit.</p>
                        </div>
                        <div class="col-lg-4 py-3 wow fadeInUp">
                            <div class="display-3"><span class="mai-shapes"></span></div>
                            <h5>Friendly Prices</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum, sit.</p>
                        </div>
                        <div class="col-lg-4 py-3 wow fadeInUp">
                            <div class="display-3"><span class="mai-shapes"></span></div>
                            <h5>No time-confusing</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum, sit.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-section border-top" id="pricing">
                <div class="container">
                    <div class="text-center wow fadeInUp">
                        <h2 class="title-section"><span class="marked">Pricing</span> Plan</h2>
                        <div class="divider mx-auto"></div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-auto py-3 wow fadeInLeft">
                            <div class="card-pricing">
                                <div class="header">
                                    <div class="price-icon"><span class="mai-people"></span></div>
                                    <div class="price-title">Membership</div>
                                </div>
                                <div class="body py-3">
                                    <div class="price-tag">
                                        <span class="currency">$</span>
                                        <h2 class="display-4">30</h2>
                                        <span class="period">/monthly</span>
                                    </div>
                                    <div class="price-info">
                                        <p>Choose the plan that right for you</p>
                                    </div>
                                </div>
                                <div class="footer">
                                    <a href="#" class="btn btn-outline rounded-pill">Choose Plan</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-auto py-3 wow fadeInUp">
                            <div class="card-pricing active">
                                <div class="header">
                                    <div class="price-labled">Best</div>
                                    <div class="price-icon"><span class="mai-business"></span></div>
                                    <div class="price-title">Dedicated</div>
                                </div>
                                <div class="body py-3">
                                    <div class="price-tag">
                                        <span class="currency">$</span>
                                        <h2 class="display-4">60</h2>
                                        <span class="period">/monthly</span>
                                    </div>
                                    <div class="price-info">
                                        <p>Choose the plan that right for you</p>
                                    </div>
                                </div>
                                <div class="footer">
                                    <a href="#" class="btn btn-outline rounded-pill">Choose Plan</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-auto py-3 wow fadeInRight">
                            <div class="card-pricing">
                                <div class="header">
                                    <div class="price-icon"><span class="mai-rocket-outline"></span></div>
                                    <div class="price-title">Private</div>
                                </div>
                                <div class="body py-3">
                                    <div class="price-tag">
                                        <span class="currency">$</span>
                                        <h2 class="display-4">90</h2>
                                        <span class="period">/monthly</span>
                                    </div>
                                    <div class="price-info">
                                        <p>Choose the plan that right for you</p>
                                    </div>
                                </div>
                                <div class="footer">
                                    <a href="#" class="btn btn-outline rounded-pill">Choose Plan</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="page-section border-top" id="about-us">
                <div class="container">
                    <div class="text-center wow fadeInUp">
                        {/* <div class="subhead">Our Blog</div> */}
                        <h2 class="title-section"><span class="marked">About</span> US </h2>
                        <div class="divider mx-auto"></div>
                    </div>
                    <div class="row my-5 card-blog-row">
                        <div class="col-md-6 col-lg-3 py-3 wow fadeInUp">
                            <div class="card-blog">
                                <div class="header">
                                    <div class="entry-footer">
                                        <div class="post-author">Sam Newman</div>
                                        <a href="#" class="post-date">23 Apr 2020</a>
                                    </div>
                                </div>
                                <div class="body">
                                    <div class="post-title"><a href="blog-single.html">What is Business Management?</a></div>
                                </div>
                                <div class="footer">
                                    <a href="blog-single.html">Read More <span class="mai-chevron-forward text-sm"></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 py-3 wow fadeInUp">
                            <div class="card-blog">
                                <div class="header">
                                    <div class="avatar">
                                        <img src="../assets/img/person/person_1.jpg" alt="" />
                                    </div>
                                    <div class="entry-footer">
                                        <div class="post-author">Sam Newman</div>
                                        <a href="#" class="post-date">23 Apr 2020</a>
                                    </div>
                                </div>
                                <div class="body">
                                    <div class="post-title"><a href="blog-single.html">What is Business Management?</a></div>
                                    <div class="post-excerpt">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                                </div>
                                <div class="footer">
                                    <a href="blog-single.html">Read More <span class="mai-chevron-forward text-sm"></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 py-3 wow fadeInUp">
                            <div class="card-blog">
                                <div class="header">
                                    <div class="avatar">
                                        <img src="../assets/img/person/person_2.jpg" alt="" />
                                    </div>
                                    <div class="entry-footer">
                                        <div class="post-author">Sam Newman</div>
                                        <a href="#" class="post-date">23 Apr 2020</a>
                                    </div>
                                </div>
                                <div class="body">
                                    <div class="post-title"><a href="blog-single.html">What is Business Management?</a></div>
                                    <div class="post-excerpt">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                                </div>
                                <div class="footer">
                                    <a href="blog-single.html">Read More <span class="mai-chevron-forward text-sm"></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 py-3 wow fadeInUp">
                            <div class="card-blog">
                                <div class="header">
                                    <div class="avatar">
                                        <img src="../assets/img/person/person_3.jpg" alt="" />
                                    </div>
                                    <div class="entry-footer">
                                        <div class="post-author">Sam Newman</div>
                                        <a href="#" class="post-date">23 Apr 2020</a>
                                    </div>
                                </div>
                                <div class="body">
                                    <div class="post-title"><a href="blog-single.html">What is Business Management?</a></div>
                                    <div class="post-excerpt">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
                                </div>
                                <div class="footer">
                                    <a href="blog-single.html">Read More <span class="mai-chevron-forward text-sm"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center">
                        <a href="blog.html" class="btn btn-outline-primary rounded-pill">Discover More</a>
                    </div>
                </div>
            </div>
            <div class="page-section" id="contact-us">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 py-3 wow fadeInUp">
                            <h2 class="title-section">Get in <span class="marked">Touch</span></h2>
                            <div class="divider"></div>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.<br /> Laborum ratione autem quidem veritatis!</p>

                            <ul class="contact-list">
                                <li>
                                    <div class="icon"><span class="mai-map"></span></div>
                                    <div class="content">A303 Vardan Tower, Near Pragati nagar, Ahmedabad - 380013</div>
                                </li>
                                <li>
                                    <div class="icon"><span class="mai-mail"></span></div>
                                    <div class="content"><a href="#">info@kpswebsolution.com</a></div>
                                </li>
                                <li>
                                    <div class="icon"><span class="mai-phone-portrait"></span></div>
                                    <div class="content"><a href="#">+91-9998669006</a></div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 py-3 wow fadeInUp">
                            <div class="subhead">Contact <span class="marked">Us</span></div>
                            <h2 class="title-section"><span class="marked">Drop</span> Us a Line</h2>
                            <div class="divider"></div>

                            <form action="#">
                                <div class="py-2">
                                    <input type="text" class="form-control" placeholder="Full name" />
                                </div>
                                <div class="py-2">
                                    <input type="text" class="form-control" placeholder="Email" />
                                </div>
                                <div class="py-2">
                                    <textarea rows="6" class="form-control" placeholder="Enter message"></textarea>
                                </div>
                                <button type="submit" class="btn btn-primary rounded-pill mt-4">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

           

          
        </main>


    )
}
export default VisitBody